var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Új hozzáadása"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"xs":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Név"}},[_c('validation-provider',{attrs:{"name":"név","rules":"required","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"név"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xs":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Frissítési gyakoriság"}},[_c('validation-provider',{attrs:{"name":"frissítési gyakoriság","rules":"required","vid":"updateFrequency"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"number","placeholder":"frissítési gyakoriság"},model:{value:(_vm.formData.updateFrequency),callback:function ($$v) {_vm.$set(_vm.formData, "updateFrequency", $$v)},expression:"formData.updateFrequency"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xs":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Aktív"}},[_c('validation-provider',{attrs:{"name":"aktív","rules":"required","vid":"active"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.activeOptions},model:{value:(_vm.formData.active),callback:function ($$v) {_vm.$set(_vm.formData, "active", $$v)},expression:"formData.active"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"xs":"12"}},[_c('b-form-group',{attrs:{"label":"URL"}},[_c('validation-provider',{attrs:{"name":"url","rules":"required","vid":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"url"},model:{value:(_vm.formData.url),callback:function ($$v) {_vm.$set(_vm.formData, "url", $$v)},expression:"formData.url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2 mb-3",attrs:{"xs":"12"}},[(this.parameters.length < this.parameterOptions.length)?_c('b-button',{staticClass:"float-right",attrs:{"variant":"info"},on:{"click":function($event){$event.preventDefault();return _vm.addNewParameter.apply(null, arguments)}}},[_vm._v(" Új paraméter ")]):_vm._e(),_c('span',[_vm._v("Paraméterek:")]),_c('br'),_c('span',{staticClass:"font-small-1 font-weight-bold"},[_vm._v("* Azonosító - amit visszakapsz a végponttól; Típus - amivel szeretnéd megfeleltetni a helyi rendszerben")])],1)],1),_vm._l((_vm.parameters),function(parameter){return _c('b-row',{key:parameter.id},[_c('b-col',{attrs:{"xs":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Azonosító"}},[_c('validation-provider',{attrs:{"name":"azonosito","rules":"required","vid":"value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"azonosító"},model:{value:(parameter.name),callback:function ($$v) {_vm.$set(parameter, "name", $$v)},expression:"parameter.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"xs":"12","md":"5"}},[_c('b-form-group',{attrs:{"label":"Típus"}},[_c('validation-provider',{attrs:{"name":"típus","rules":"required","vid":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.parameterOptions,"selectable":function (option) { return option.selectable; }},on:{"input":_vm.parameterSelected},model:{value:(parameter.value),callback:function ($$v) {_vm.$set(parameter, "value", $$v)},expression:"parameter.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.parameters.length > 1)?_c('b-col',{attrs:{"xs":"12","md":"1"}},[_c('b-button',{staticClass:"float-right mt-2",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.removeParameter(parameter.id)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1)],1):_vm._e()],1)}),_c('b-row',{staticClass:"mt-5"},[_c('b-col',{staticClass:"mt-1"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" Mentés ")]),_c('cancel-and-g-o-t-o-previous-page',{staticClass:"mr-2"})],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }